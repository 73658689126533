import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const GuidesCableLengthTemplate = () => (
  <Layout>
    <SEO title="Длины кабеля и провода, наматываемого на барабан" />
    <div>
      <span><Link to={`/guides/`}>Справочник</Link></span>
      <span> > </span>
      <span>Длины кабеля и провода, наматываемого на барабан</span>
    </div>
    <main>
    <h1>Длины кабеля и провода, наматываемого на барабан</h1>
    <table>
      <thead>
        <tr>
          <th rowSpan="2" scope="row">Диаметр кабеля, мм</th>
          <th rowSpan="1" colSpan="14" scope="col">Номер барабана</th>
        </tr>
        <tr>
          <th scope="col">5</th>
          <th scope="col">6</th>
          <th scope="col">8</th>
          <th scope="col">10</th>
          <th scope="col">12</th>
          <th scope="col">14</th>
          <th scope="col">16</th>
          <th scope="col">17</th>
          <th scope="col">18</th>
          <th scope="col">20</th>
          <th scope="col">22</th>
          <th scope="col">25</th>
          <th scope="col">26</th>
          <th scope="col">30</th>
        </tr>
      </thead>
      <tbody>
      <tr>
        <td>5</td>
        <td>900</td>
        <td>1300</td>
        <td>3500</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>7,5</td>
        <td>450</td>
        <td>650</td>
        <td>1700</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>10</td>
        <td>200</td>
        <td>350</td>
        <td>900</td>
        <td>2200</td>
        <td>3000</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>15</td>
        <td>100</td>
        <td>150</td>
        <td>400</td>
        <td>1000</td>
        <td>1400</td>
        <td>2800</td>
        <td>1700</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>20</td>
        <td>-</td>
        <td>100</td>
        <td>200</td>
        <td>600</td>
        <td>800</td>
        <td>1700</td>
        <td>1000</td>
        <td>2100</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>25</td>
        <td>-</td>
        <td>-</td>
        <td>140</td>
        <td>350</td>
        <td>500</td>
        <td>1000</td>
        <td>650</td>
        <td>1400</td>
        <td>1700</td>
        <td>2100</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>30</td>
        <td>-</td>
        <td>-</td>
        <td>100</td>
        <td>250</td>
        <td>350</td>
        <td>700</td>
        <td>450</td>
        <td>1000</td>
        <td>1200</td>
        <td>1900</td>
        <td>2100</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>35</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>200</td>
        <td>250</td>
        <td>500</td>
        <td>350</td>
        <td>700</td>
        <td>850</td>
        <td>1200</td>
        <td>1500</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>40</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>130</td>
        <td>200</td>
        <td>400</td>
        <td>250</td>
        <td>550</td>
        <td>650</td>
        <td>900</td>
        <td>1100</td>
        <td>1800</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>45</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>300</td>
        <td>200</td>
        <td>450</td>
        <td>550</td>
        <td>700</td>
        <td>900</td>
        <td>1500</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>50</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>250</td>
        <td>150</td>
        <td>350</td>
        <td>420</td>
        <td>570</td>
        <td>1200</td>
        <td>1700</td>
        <td>2600</td>
        <td>-</td>
      </tr>
      <tr>
        <td>55</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>270</td>
        <td>350</td>
        <td>450</td>
        <td>600</td>
        <td>950</td>
        <td>1400</td>
        <td>2200</td>
      </tr>
      <tr>
        <td>60</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>240</td>
        <td>280</td>
        <td>400</td>
        <td>500</td>
        <td>800</td>
        <td>1200</td>
        <td>1800</td>
      </tr>
      <tr>
        <td>65</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>200</td>
        <td>250</td>
        <td>340</td>
        <td>450</td>
        <td>700</td>
        <td>1000</td>
        <td>1500</td>
      </tr>
      <tr>
        <td>70</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>220</td>
        <td>300</td>
        <td>370</td>
        <td>600</td>
        <td>850</td>
        <td>1350</td>
      </tr>
      <tr>
        <td>80</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>220</td>
        <td>290</td>
        <td>450</td>
        <td>650</td>
        <td>1000</td>
      </tr>
      <tr>
        <td>90</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>320</td>
        <td>460</td>
        <td>700</td>
      </tr>
      <tr>
        <td>100</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>290</td>
        <td>400</td>
        <td>650</td>
      </tr>
      </tbody>
    </table>
    </main>
  </Layout>
)

export default GuidesCableLengthTemplate
